
export const SecurityHint = ({msg})=>{
    return(
        <div style={{ display:"flex" ,justifyContent:"center"}}>
            <div style={{backgroundColor:"#CECECE",minWidth:"199px",textAlign:"center",maxWidth:"248px",height:"33px"}}>
                <div style={{fontSize:"12px",fontFamily: "PingFang SC",marginTop:"4px",fontWeight:"normal",lineHeight:"24px","color":"#FFFFFF",marginLeft:"16px",marginRight:"16px"}}>{msg}</div>
            </div>
        </div>
        
        
    );
}