import { Button,Modal } from 'antd';
import { Input } from 'antd';
import {useState} from 'react';
import { Divider } from 'antd';
import {useSelector,useDispatch} from 'react-redux';
import {ReducerType} from '../../store/reducer';

import './footer.css';
import {CloseOutlined  } from '@ant-design/icons';

const { TextArea } = Input;

const ConfimrContent = ()=>{
    const dataPhone = useSelector(state => state.dataPhone);
    const dispatch = useDispatch();
    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowDialConfirm,data:false});
    }
    const handleDial = (e)=>{
        window.open('tel:'+dataPhone);
    }
    return(
    <div style={{textAlign:"center"}}>
        <Button style={{position:"absolute",top:"-15px",right:"-15px" }}  shape="circle"  icon={<CloseOutlined onClick={handleCancel}/>}></Button>
        <div style={{fontSize:"16px",fontFamily:"PingFang SC",fontWeight:"400",color:"#333333"}}>温馨提示</div>
        <div style={{color:"#999999",fontSize:"14px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>特惠8元/分钟（原价10元/分钟）先咨询，后付费</div>
        {/* <hr style={{color:"#EEEEEE",}}></hr> */}
        <Divider style={{width:"100%",marginTop:"12px",marginBottom:"12px"}}></Divider>
        <Button  type="text" style={{color:"#FF8600",fontSize:"16px",fontWeight:"normal"}} onClick={handleDial}>立即咨询</Button>   

    </div>);
}


const ConfirmCopy = ({msg}) =>{
    const dispatch = useDispatch();
    const dataWechat = useSelector(state => state.dataWechat);
    // if(navigator.clipboard!=undefined){
    //     navigator.clipboard.writeText(dataWechat).then(function() {
    //         /* clipboard successfully set */
    //         console.log("复制成功",dataWechat,typeof(dataWechat));
    //       }, function(data) {
    //         /* clipboard write failed */
    //         console.log("复制失败",data);
    //       });
    // }
    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowCopyConfirm,data:false});
    }
    const openWechat = (e)=>{
        // location.href="http://weixin.qq.com/";
        window.open("weixin://");
        // window.open("baiduyun://");
        // window.open("chrome://www.baidu.com");
        
    }
    return(
        <div style={{textAlign:"center"}}>
            <Button style={{position:"absolute",top:"14px",right:"14px" ,border:"0"}}  icon={<CloseOutlined onClick={handleCancel}/>}></Button>
            <div style={{fontSize:"16px",fontFamily:"PingFang SC",fontWeight:"bold",color:"#333333"}}>复制成功</div>
            <div style={{color:"#333333",fontSize:"14px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>微信号：{dataWechat}</div>
            <div style={{color:"#333333",fontSize:"14px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>添加微信好友，了解{msg}</div>
            {/* <hr style={{color:"#EEEEEE",}}></hr> */}
            <Button  style={{color:"white",height:"34px",fontSize:"16px",fontWeight:"normal",border:"0",backgroundColor:"#17C239",borderRadius:"4px",marginTop:"10px"}} onClick={openWechat} >打开微信</Button> 
        </div>
    );
}


export const FooterBar = ({onSendMsg,onBtnEvent})=>{
    const [msg,setMsg] = useState("");
    const showCopy = useSelector(state => state.uIShowCopyConfirm);
    const [copyMsg,setCopyMsg] = useState("");
    const showConfirm = useSelector(state => state.uIShowDialConfirm);
    const dispatch = useDispatch();
    console.log("是否显示",showConfirm);
    // const [showConfirm,setShowConfirm] = useState(false);
    const handleConfirm=()=>{
        // setShowConfirm(true);
        console.log("电话咨询");
        dispatch({type:ReducerType.UIShowDialConfirm,data:true});
    }

    const copyWechat = ()=>{
        // let inputElement = document.getElementById('wechat-copy-input');
        var inputElement = document.querySelector("#wechat-copy-input");
        let r = inputElement.select();
        
        console.log("复制的节点",r,inputElement);
        document.execCommand('copy');
        inputElement.blur();
    }

    return(
        <div style={{backgroundColor:"#F7F7F7"}}>
            <div style={{display:"flex",justifyContent:"space-evenly",paddingTop:"8px",paddingBottom:"8px"}}>
                    <div style={{fontSize:"14px",lineHeight:"20px",
                    fontFamily:"PingFang SC",fontWeight:"400",color:"#666666",paddingTop:"5px",paddingBottom:"5px"}}>我想</div>
                    <Button  
                        style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"12px",paddingRight:"12px",borderRadius:"17px"}} onClick={handleConfirm}>
                        电话咨询(8元/分钟)</Button>
                    <Button 
                        onClick={(e)=>{
                            // onBtnEvent(2);
                            copyWechat();
                            setCopyMsg("打官司");
                            dispatch({type:ReducerType.UIShowCopyConfirm,data:true});
                        }}
                        style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"12px",paddingRight:"12px",borderRadius:"17px"}}>
                        打官司</Button>
                    <Button 
                        onClick={(e)=>{
                            // onBtnEvent(3)
                            copyWechat();
                            setCopyMsg("合同协议");
                            dispatch({type:ReducerType.UIShowCopyConfirm,data:true});
                        }}
                        style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"12px",paddingRight:"12px",borderRadius:"17px"}}>
                        合同协议</Button>   
            </div>
            <div style={{display:"flex",justifyContent:"space-evenly"}}>
                <TextArea style={{marginTop:"6px",marginBottom:"6px",marginLeft:"16px"}} autoSize={{ minRows: 1, maxRows: 3 }} value={msg} onChange={(e)=>setMsg(e.target.value)}></TextArea>
                <Button 
                    onClick={(e)=>{
                        
                        if(onSendMsg!==undefined){
                            onSendMsg(msg);
                            setMsg("");
                        }
                    }}
                    style={{borderRadius:"5px",marginTop:"6px",marginBottom:"6px",marginRight:"16px",marginLeft:"8px",backgroundColor:"#FF8600"}}>
                    发送</Button>
            </div>
            <Modal
                title={null}
                visible={showConfirm}
                footer={null}
                width="275px"
                centered
                closable={false}
                style={{paddingLeft:"0px"}}
            >
                <ConfimrContent></ConfimrContent>
            </Modal>
            <Modal
                title={null}
                visible={showCopy}
                footer={null}
                width="285px"
                centered
                closable={false}
                style={{paddingLeft:"0px"}}
            >
                <ConfirmCopy msg={copyMsg}></ConfirmCopy>
            </Modal>
        </div>
    
    );
}