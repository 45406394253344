import { Button, Divider,Checkbox  } from 'antd';
import { LeftOutlined ,CloseOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import './index.css';
import { useHistory } from "react-router-dom";

import shibai from '../res/shibai.png';
import chenggong from '../res/chenggong.png';

const PayFail = ()=>{
    return(
        <div style={{width:"100%",marginTop:"calc( 50% - 50px )"}}>
                {/* <CloseOutlined style={{width:"62px",height:"62px",backgroundColor:"#04be02",fontSize:"40px",color:"white",padding:"7px",fontWeight:"900",borderRadius:"30px"}}></CloseOutlined> */}
            <img src={shibai} style={{height:"62px",width:"62px"}}></img>
            <div style={{marginTop:"16px",fontSize:"20px",color:"#333333", fontWeight:"400"}}>
                支付失败
            </div>
            <div style={{fontSize:"14px",color:"#999999"}}>
                支付已取消，请重试！
            </div>
            
            <Button style={{marginLeft:"16px",marginRight:"16px",backgroundColor:"#FF8600",
                width:"calc( 100% - 32px)", border:"0", color:"#FFFFFF",
                height:"45px",borderRadius:"8px",
                marginTop:"50px",
                paddingTop:"12px",paddingBottom:"12px",
                fontSize:"16px", fontFamily:"PingFang SC"}}>
                    重新支付
            </Button>
        </div>
    );
}

const PaySucceed = ({money})=>{
    let history = useHistory();

    const goback = ()=>{
        
        history.push('/');
       
    }
    return(
        <div style={{width:"100%",marginTop:"calc( 50% - 50px )"}}>
            <img src={chenggong} style={{height:"62px",width:"62px"}}></img>
            <div style={{marginTop:"16px",fontSize:"20px",color:"#333333", fontWeight:"400"}}>
                支付成功
            </div>
            <div style={{fontSize:"14px",color:"#999999"}}>
            已成功支付{money}元
            </div>
            
            <Button onClick={goback} style={{marginLeft:"16px",marginRight:"16px",backgroundColor:"#FF8600",
                width:"calc( 100% - 32px)", border:"0", color:"#FFFFFF",
                height:"45px",borderRadius:"8px",
                marginTop:"50px",
                paddingTop:"12px",paddingBottom:"12px",
                fontSize:"16px", fontFamily:"PingFang SC"}}>
                    返回
            </Button>
        </div>
    );
}


export const PayResult = ()=>{
    let history = useHistory();
    const goback = ()=>{
        history.push('/');
       
    }
    const [isZhifubao,setIsZhifubao] = useState(true);
    // const dataPaySuccess = useSelector(state => state.dataPaySuccess);
    console.log( history.location.state);
    const getQueryString = (name, search) => {
        search = search ||  window.location.search.substr(1) || window.location.hash.split("?")[1];
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        if(search===undefined){
            return "";
        }
        let r = search.match(reg);
        if (r != null) return  unescape(r[2]); return null;
      }
    let money = getQueryString("v",false);
    console.log("payed",money);
    return(
    <div style={{textAlign:"center",height:"100%",backgroundColor:"#F2F2F2"}} className="mobile-container">
        <div className="header" style={{height:"44px",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Button shape="circle" onClick={goback} icon={<LeftOutlined />} style={{position:"absolute",left:"15px",border:"0"}}></Button>
            <div style={{fontWeight:"bold",fontFamily:"PingFang SC",fontSize:"18px",color:"#333333"}}>支付结果</div>
        </div>
        <div style={{display:"flex",height:"100%" }}>
            {/* {dataPaySuccess?<PaySucceed></PaySucceed>:<PayFail></PayFail>}  */}
            <PaySucceed money={money}></PaySucceed>
        </div> 
    </div>);
}