

export const parseQueryString = (name, search) => {
    console.log(window.location);
    search = search ||  window.location.search.substr(1) || window.location.hash.split("?")[1];
    let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    if(search===undefined){
        return "";
    }
    let r = search.match(reg);
    if (r != null) return  unescape(r[2]); return null;
}


export const parseKey = (name) => {
    let search =  window.location.hash.split("?")[1];
    let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    if(search===undefined){
        return "";
    }
    let r = search.match(reg);
    if (r != null) return  unescape(r[2]); return null;
}



export const parseHref = (name) => {
    console.log("parseHref");
    let search =  window.location.href;
    console.log(search);
    let reg = new RegExp("(^|&?)"+ name +"=([^&|^#]*)(#|&|$)");
    if(search===undefined){
        return "";
    }
    
    let r = search.match(reg);
    console.log(r,reg);
    if(r ===null || r.length<2){ 
        return null;
    }
    console.log(unescape(r[2]))
    if (r != null) return  unescape(r[2]); return null;
}