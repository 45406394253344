import { Button,Divider,Input,message } from 'antd';
import {CloseOutlined  } from '@ant-design/icons';
import {useDispatch} from 'react-redux';
import {ReducerType} from '../../store/reducer';
import {getVerifyCode,verifyMobile} from '../../service/verify';
import {useState,useEffect} from 'react';

import './input-phone.css';

const { Search } = Input;

export const InputPhone = ({setLoginCb})=>{
    const dispatch = useDispatch();
    const [mobile,setMobile] = useState("");
    const [code,setCode] = useState("");
    const [countDown,setCountDown] = useState(0);
    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowInputMobile,data:false});
    }

    
    useEffect(()=>{
       
        if(countDown>0){
            setTimeout(() => {
                console.log("启动计时器");
                setCountDown(countDown - 1);
            }, 1000);

        }
    },[countDown]);
    const onSearch = (e)=>{
        if(mobile.length<=10){
            message.error({
                content: "手机号格式错误",
                style: {
                    marginTop: '20vh',
                },
                duration:2 
                });
            return;
        }
        document.getElementById("verifyCode").focus();

        getVerifyCode(mobile,(resp)=>{
            if(resp.data.code==200){
                setCountDown(60);
                setTimeout(() => {
                    console.log("启动计时器");
                    // startCount(60);
                }, 1000);

                message.success({
                    content: "请查收短信验证码",
                    style: {
                      marginTop: '20vh',
                    },
                    duration:2 
                  });
            }else{
                message.error({
                    content: resp.data.message,
                    style: {
                        marginTop: '20vh',
                    },
                    duration:2 
                    });
            }
            console.log("请求验证码的结果",resp);
        });
        console.log(e);
    }

    const startCount = (c) =>{
        let myInterval = setInterval(() => {
            console.log("执行定时器",countDown);
            if (countDown > 0) {
                setCountDown(countDown - 1);
            }
            if (countDown === 0) { 
                clearInterval(myInterval);
            } 
        }, 1000);    
    }
    
    const onLogin = ()=>{
        verifyMobile(mobile,code,(resp=>{
            console.log("结果",resp);
            if(resp.data.code==200){
                //   dispatch({type:ReducerType.DataVerifiedMobile,data:resp.data.data.phone});
                localStorage.setItem('phone',resp.data.data.phone);
                  dispatch({type:ReducerType.UIShowInputMobile,data:false});
                  if(setLoginCb!==undefined && localStorage.getItem('doLoginCb')=="true"){
                    setLoginCb();
                    localStorage.removeItem('doLoginCb');
                    // loginCb = undefined;
                }
            }else{
                message.error({
                    content: resp.data.message,
                    style: {
                      marginTop: '20vh',
                    },
                    duration:2 
                });
            }
        }))
        console.log("登录手机号",mobile,code);
    }
    const isBtnAble = countDown>0?true:false;
    // const bg = countDown>0?"#CCCCCC":"#FF8600";
    // console.log("背景",bg);
    return(
    <div style={{textAlign:"center",borderRadius:"13px"}}>
            <Button style={{position:"absolute",top:"-15px",right:"-15px" }} onClick={handleCancel}  shape="circle"  icon={<CloseOutlined ></CloseOutlined>}></Button>
            <div style={{fontSize:"17px",fontFamily:"PingFang SC",fontWeight:"400",color:"#333333"}}>登录后一对一咨询</div>
            <Input style={{borderRadius:"3px",marginTop:"18px"}} placeholder="请输入手机号" size="large" onChange={(e)=>{setMobile(e.target.value);console.log(mobile)}}></Input>
            <div style={{display:"flex",marginTop:"12px",marginBottom:"12px",height:"40px"}}>
                <span className="ant-input-affix-wrapper ant-input-affix-wrapper-lg" style={{width:"157px"}}>
                    <input placeholder="输入验证码" id="verifyCode" className="ant-input ant-input-lg" onChange={(e)=>{setCode(e.target.value);console.log(code);}}></input>
                </span>
                <Button style={ {borderRadius:"0px",backgroundColor:countDown>0?"#CCCCCC":"#FF8600",textAlign:"center",
                    height:"100%",borderTopLeftRadius:"0px",width:"100px",fontSize:"14px",marginRight:"0px",
                    color:"white",border:"none"}} onClick={onSearch} disabled={isBtnAble} >
                         {countDown>0?countDown+"s":"获取验证码"} 
                </Button>
            </div>
             <Button block size="large" type="primary"
                style={{backgroundColor:"#FF8600",border:"none"}}
              onClick={onLogin}>登录</Button>
        </div>
    );
}