import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RouterConfig} from './routers';
import {Provider} from 'react-redux';
import Store from './store/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <RouterConfig />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// window.addEventListener('resize', function() {
//   if(document.activeElement.tagName === 'INPUT' ||
//     document.activeElement.tagName === 'TEXTAREA') {
//     window.setTimeout(function() {
//       if('scrollIntoView' in document.activeElement) {
//         document.activeElement.scrollIntoView();
//       } else {
//         document.activeElement.scrollIntoViewIfNeeded();
//       }
//     }, 0);
//   }
// });