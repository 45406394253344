import { Button,Modal,message } from 'antd';
import { Input } from 'antd';
import {useState,useRef } from 'react';
import { Divider } from 'antd';
import {useSelector,useDispatch} from 'react-redux';
import {ReducerType} from '../../store/reducer';
import {ChargeInfo} from './modal-charge-info';
import {CreateDialBack} from '../../service/dial_back';
import './footer.css';
import {CloseOutlined  } from '@ant-design/icons';
import {InputPhone} from './input-phone';
import { useHistory } from "react-router-dom";
import {QueryUnpaidOrder} from '../../service/query_unpaid_order';
const { TextArea } = Input;

const ConfimrContent = ()=>{
    const dataPhone = localStorage.getItem('lawyer_phone');
    // const verifiedMobile = useSelector(state => state.dataVerifiedMobile);
    let history = useHistory();

    const dispatch = useDispatch();
    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowDialConfirm,data:false});
    }
    const handleDial = (e)=>{
        // if(localStorage.getItem('phone')!==null){
        //     //请求是否存在未付费订单，如果存在，则跳转付费。
        //     QueryUnpaidOrder(localStorage.getItem('phone'),(resp)=>{
        //         if(resp.data.code==302){    //有订单未付款，弹出提示，延迟2秒，并跳转到付款界面。
        //             message.error({
        //                 content: resp.data.message,
        //                 style: {
        //                     marginTop: '20vh',
        //                 },
        //                 duration:2 
        //             });
        //             setTimeout(() => {
        //                 // window.location.href = "/pay?order_no="+resp.data.data;
        //                 history.push({
        //                     pathname:"/pay",
        //                     search:"?order_no="+resp.data.data
        //                 })
        //                 console.log("跳转到付款");
        //             },2000);
        //         }else{
        //             window.open('tel:'+dataPhone);
        //         }
            
        //     })
        // }else{
        //     window.open('tel:'+dataPhone);
        // }
        window.open('tel:'+dataPhone);
       
        
    }
    return(
    <div style={{textAlign:"center"}}>
        <Button style={{position:"absolute",top:"-15px",right:"-15px" }}  shape="circle" onClick={handleCancel} icon={<CloseOutlined />}></Button>
        <div style={{fontSize:"16px",fontFamily:"PingFang SC",fontWeight:"400",color:"#333333"}}>温馨提示</div>
        <div style={{color:"#999999",fontSize:"14px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>特惠8元/分钟（原价10元/分钟）先咨询，后付费</div>
        {/* <hr style={{color:"#EEEEEE",}}></hr> */}
        <Divider style={{width:"100%",marginTop:"12px",marginBottom:"12px"}}></Divider>
        <Button  type="text" style={{color:"#FF8600",fontSize:"16px",fontWeight:"normal",width:"80%"}} onClick={handleDial}>立即咨询</Button>   

    </div>);
}


const ConfirmCopy = ({msg}) =>{
    const dispatch = useDispatch();
    const dataWechat = useSelector(state => state.dataWechat);

    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowCopyConfirm,data:false});
    }
    const openWechat = (e)=>{
        // location.href="http://weixin.qq.com/";
        window.open("weixin://");
    }
    return(
        <div style={{textAlign:"center"}}>
            <Button style={{position:"absolute",top:"14px",right:"14px" ,border:"0"}}  icon={<CloseOutlined onClick={handleCancel}/>}></Button>
            <div style={{fontSize:"16px",fontFamily:"PingFang SC",fontWeight:"bold",color:"#333333"}}>复制成功</div>
            <div style={{color:"#333333",fontSize:"14px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>微信号：{dataWechat}</div>
            <div style={{color:"#333333",fontSize:"14px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>添加微信好友，了解{msg}</div>
            {/* <hr style={{color:"#EEEEEE",}}></hr> */}
            <Button  style={{color:"white",height:"34px",fontSize:"16px",fontWeight:"normal",border:"0",backgroundColor:"#17C239",borderRadius:"4px",marginTop:"10px"}} onClick={openWechat} >打开微信</Button> 
        </div>
    );
}


export const FooterBarUC = ({onSendMsg,onBtnEvent})=>{
    const [msg,setMsg] = useState("");
    const showCopy = useSelector(state => state.uIShowCopyConfirm);
    const showInputMobile = useSelector(state => state.uIShowInputMobile);
    const showChargeInfo = useSelector(state => state.uIShowChargeInfo);
    
    const lawerId = useSelector(state => state.dataLawerId);
    const [copyMsg,setCopyMsg] = useState("");
    const showConfirm = useSelector(state => state.uIShowDialConfirm);
    // const verifiedMobile = useSelector(state => state.dataVerifiedMobile);
    const dispatch = useDispatch();
    let history = useHistory();
    const inputRef = useRef();
    // let loginCb = null;
    // console.log("是否显示",showConfirm);
    // const [showConfirm,setShowConfirm] = useState(false);
    const handleConfirm=()=>{
        // setShowConfirm(true);
        console.log("电话咨询");
        // dispatch({type:ReducerType.UIShowDialConfirm,data:true});
        window.open('tel:'+localStorage.getItem('lawyer_phone'));
    }

    const copyWechat = ()=>{
        // let inputElement = document.getElementById('wechat-copy-input');
        var inputElement = document.querySelector("#wechat-copy-input");
        let r = inputElement.select();
        
        console.log("复制的节点",r,inputElement);
        document.execCommand('copy');
        inputElement.blur();
    }
    const handleJump = (isDeal)=>{
        // console.log(verifiedMobile,verifiedMobile.length);
        // loginCb = null;
        console.log(localStorage.getItem('phone'));
        if(localStorage.getItem('phone')===undefined || localStorage.getItem('phone')===null || localStorage.getItem('phone').length===0){//未登录手机号，需要登录。
            dispatch({type:ReducerType.UIShowInputMobile,data:true});
        }else{
            if(isDeal){ 
                history.push("/lawbook");
            }else{
              
                history.push('/lawsuit');
            }
        }

    }
    const inputMobileCb = ()=>{
        console.log("关闭的回调",localStorage.getItem('phone'));
        if(msg===undefined || msg.trim().length === 0){
            return;
        }
        if(onSendMsg!==undefined){
            onSendMsg(msg);
            setMsg("");
            console.log("律师的id",lawerId);
            //请求创建通话。
            CreateDialBack(localStorage.getItem('phone'),localStorage.getItem('lawyer_id'),msg,(resp)=>{
                if(resp.data.code==302){    //有订单未付款，弹出提示，延迟2秒，并跳转到付款界面。
                    message.error({
                        content: resp.data.message,
                        style: {
                          marginTop: '40vh',
                        },
                        duration:2 
                      });
                    setTimeout(() => {
                        history.push({
                            pathname:"/pay",
                            search:"?order_no="+resp.data.data
                        })
                        console.log("跳转到付款");
                    },3000);
                }else if(resp.data.code === 200){
                    dispatch({type:ReducerType.DataAxBPhoneA,data:resp.data.data.phone});
                }else if (resp.data.code === 503){
                    //律师不在线，
                    console.log("律师不在线");
                }
            })
            
        }
    }
    const handleSendMsg = (e)=>{
        if(localStorage.getItem('phone')===undefined || localStorage.getItem('phone')===null || localStorage.getItem('phone').length===0){//未登录手机号，需要登录。
            console.log(inputRef);
            localStorage.setItem('doLoginCb','true');
            dispatch({type:ReducerType.UIShowInputMobile,data:true});
        }else{
            console.log("已留号码,发送文字，延迟100毫秒，然后弹出提示框");
            inputMobileCb();
        }
    }
    return(
        <div style={{backgroundColor:"#F7F7F7"}}>
            <div style={{display:"flex",justifyContent:"space-evenly",paddingTop:"8px",paddingBottom:"8px"}}>
                    <div className="btn-want" style={{display:"flex",alignItems:"center"}}>我想</div>
                    <Button  className="dial-style-remove-ant"
                        style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"12px",paddingRight:"12px",borderRadius:"17px"}} onClick={handleConfirm}>
                        电话咨询(8元/分钟)</Button>
                    <Button 
                        className="dial-style-remove-ant"
                        onClick={(e)=>{
                            // onBtnEvent(2);
                            handleJump(false);
                        }}
                        style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"12px",paddingRight:"12px",borderRadius:"17px"}}>
                        打官司</Button>
                    <Button className="dial-style-remove-ant"
                        onClick={(e)=>{
                            // onBtnEvent(3)
                            handleJump(true);
                        }}
                        style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"12px",paddingRight:"12px",borderRadius:"17px"}}>
                        合同协议</Button>   
            </div>
            <div style={{display:"flex",justifyContent:"space-evenly"}}>
                <TextArea className="dial-input" style={{marginTop:"6px",marginBottom:"6px",marginLeft:"16px"}} autoSize={{ minRows: 1, maxRows: 3 }} value={msg} onChange={(e)=>setMsg(e.target.value)}></TextArea>
                <Button 
                    onClick={handleSendMsg}
                    style={{borderRadius:"5px",border:"none",marginTop:"6px",marginBottom:"6px",marginRight:"16px",marginLeft:"8px",backgroundColor:"#FF8600"}}>
                    发送</Button>
            </div>
            <Modal
                title={null}
                visible={showConfirm}
                footer={null}
                width="275px"
                centered
                closable={false}
                style={{paddingLeft:"0px"}}
            >
                <ConfimrContent></ConfimrContent>
            </Modal>
            <Modal
                title={null}
                visible={showInputMobile}
                footer={null}
                width="275px"
                centered
                closable={false}
                style={{paddingLeft:"0px"}}
            >
                <InputPhone setLoginCb={inputMobileCb}></InputPhone>
            </Modal>
            <Modal
                title={null}
                visible={showChargeInfo}
                footer={null}
                width="320px"
                centered
                closable={false}
                style={{paddingLeft:"0px"}}
            >
                <ChargeInfo msg={copyMsg}></ChargeInfo>
            </Modal>
        </div>
    
    );
}