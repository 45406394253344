import { Button,Divider,Input } from 'antd';
import {CloseOutlined  } from '@ant-design/icons';
import {useDispatch,useSelector} from 'react-redux';
import {ReducerType} from '../../store/reducer';
import {getVerifyCode,verifyMobile} from '../../service/verify';
import {useState} from 'react';


const { Search } = Input;

export const ChargeInfo = ()=>{
    const dispatch = useDispatch();
    const [mobile,setMobile] = useState("");
    const [code,setCode] = useState("");
    
    const lawerPhone = useSelector(state => state.dataAxBPhoneA);

    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowChargeInfo,data:false});
    }

    const onSearch = (e)=>{
        getVerifyCode(mobile,(resp)=>{
            console.log("请求验证码的结果",resp);
        });
        console.log(e);
    }
    const onLogin = ()=>{
        verifyMobile(mobile,code,(resp=>{
            console.log("结果",resp);
            dispatch({type:ReducerType.DataVerifiedMobile,data:resp.data.data.phone});
            dispatch({type:ReducerType.UIShowInputMobile,data:false});

        }))
        console.log("登录手机号",mobile,code);
    }

    return(
    <div style={{textAlign:"center",borderRadius:"13px"}}>
            <div style={{fontSize:"17px",fontFamily:"PingFang SC",fontWeight:"400",color:"#333333"}}>温馨提示</div>
            <div style={{marginTop:"13px"}}>您的问题比较复杂，需要打电话为您详细解答，我会马上打电话给您，请留意我的电话:{lawerPhone}。先咨询后付费，8元每分钟。若不方便，可挂断后继续图文咨询。</div>
           
             <Button block size="large" type="primary" onClick={handleCancel} style={{marginTop:"13px"}}>确定</Button>
        </div>
    );
}