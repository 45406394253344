
import { Button, Divider ,Input  ,Radio,Cascader,message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import './lawbook.css';
import provinces from "china-division/dist/provinces.json";
import cities from "china-division/dist/cities.json";
import { useState } from 'react';
import {AskHelp} from '../service/help';
import { useHistory } from "react-router-dom";

const { TextArea } = Input;

const BookType = ({onChangeType})=>{
    const suitOptions = [
        { label: '离婚协议', value: 1 },
        { label: '婚内财产协议', value:2  },
        { label: '起诉状', value: 3 },
        {label:'借条/借款协议',value:4},
        {label:'劳动合同',value:5},
        {label:'劳动仲裁书',value:7},
        {label:'股权合同',value:10},
        {label:'律师函',value:8},
        {label:'其他文书',value:9},
        
      ];
    const getLabel = (v)=>{
        for (let i = 0; i < suitOptions.length; i++) {
            if(suitOptions[i].value == v){
                return suitOptions[i].label;
            }
        }
    }
    return(
        <div >
            <div className="container-margin" style={{fontSize:"17px",marginTop:"16px",fontWeight:"600", marginBottom:"10px"}}>
            文书类型
            </div>
             <Radio.Group
                className="lawbook-type-container container-margin"
                onChange={e=>{
                    if(onChangeType!=undefined){
                        onChangeType(e.target.value,getLabel(e.target.value));
                    }
                }}
              style={{ marginTop:"6px"}} defaultValue={1} buttonStyle="solid" optionType="button" options={suitOptions}>
            </Radio.Group>
            
        </div>
    );
}

export const LawBook = ()=>{
    let history = useHistory();

    const [desc,setDesc] = useState("");
    const [suitType,setSuitType] = useState(1);
    const [suitTypeStr,setSuitTypeStr] = useState("");
    const [character,setCharacter] = useState(1);
    const [region,setRegion] = useState([]);
    const [regionStr,setRegionStr] = useState("");
    const handleChangeType =(v,str)=>{
        console.log("切换类型",v,str);
        setSuitType(v);
        setSuitTypeStr(str);
    }
    const goback = ()=>{
        if(history.length === 1){
            console.log("跳转过去");
            history.push('/');
        }else{
            console.log("跳转回去");
            history.goBack();
        }
       
    }
    const handSubmit = (e)=>{
        let body = "地区:"+regionStr +",描述："+desc + ",类型:"+suitTypeStr;

        AskHelp(localStorage.getItem('phone'),body,2,(resp)=>{
            if(resp.data.code==200){
                message.success({
                    content: "提交成功!法律顾问稍后将与你联系",
                    style: {
                      marginTop: '70vh',
                    },
                    duration:3
                  });
            }else{
                message.error(resp.data.message);
            }
            
        })
    }
    return (
        <div>
            <div className="header" style={{height:"44px",display:"flex",justifyContent:"center",alignItems:"center",paddingBottom:"10px",paddingTop:"10px"}}>
                <Button
                    onClick={goback}
                 shape="circle" icon={<LeftOutlined />} style={{position:"absolute",left:"15px",border:"0"}}></Button>
                <div style={{fontWeight:"bold",fontFamily:"PingFang SC",fontSize:"18px",color:"#333333"}}>我要写文书</div>
            </div>
            <Divider orientation="left" plain style={{marginTop:"0px",marginBottom:"0px"}}></Divider>

           <BookType onChangeType={handleChangeType}></BookType>
            
            <div style={{height:"10px",backgroundColor:"#F7F7F7",marginTop:"16px"}}></div>
            <div className="container-margin" style={{ marginTop:"24px"}}>
                <div style={{fontSize:"16px",fontWeight:"600",marginTop:"16px",marginBottom:"16px"}}>具体要求</div>
                <TextArea className="dial-input" style={{width:"100%"}}
                    onChange={(e)=>{setDesc(e.target.value);}}
                    placeholder="请描述您的文书诉求、背景情况以及时间要求"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    />
            </div>
            <div className="container-margin">
            <Button type="primary"
                onClick={handSubmit}
                
             block style={{height:"40px", marginTop:"24px",backgroundColor:"#FF8600",border:"none",fontSize:"16px",paddingTop:"2px"}}>提交</Button>
            </div>
            
            

        </div>
    );
}