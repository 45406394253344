export const Api = {
    // rootPath:`https://test.lvshiguan.com`,
    // rootPathUnsafe:`http://test.lvshiguan.com`,
    // rootPath:`http://dev.lvshiguan.com`,
    // rootPathUnsafe:`http://dev.lvshiguan.com`,
    rootPath:`https://api.lvshiguan.com`,
    rootPathUnsafe:`https://api.lvshiguan.com`,
    // rootPath:``,
    
    User: `/api/user`,
    Safe:`/api/safety`,
    InitInfo:`/consult/init/getInitInfo`,
    QueryPayUrl:`/consult/pay/getH5PayUrl`,         //调用此接口可获取h5支付的跳转地址。
    PrebuyUrl:`/consult/pay/prebuy`,                //调用此接口可获取h5支付的跳转地址。
    QueryOrderInfo:`/consult/init/getOrderInfo`,    //
    GetVerifyCode:`/consult/sms/getVerifyCode`,
    VerifyPhone:`/consult/sms/verifyPhone`,
    AskDialBack:`/consult/dial_back/createDial`,
    QueryUnpaidOrder:`/consult/dial_back/queryUnpaidOrder`,
    PostSuit:`/consult/law_help/suit`
}
// export default Ap