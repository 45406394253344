import { Button, Divider,Checkbox,message  } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import {useState} from 'react';
import './index.css';
import {Prebuy,queryOrder} from '../service/pay';
import {React,useEffect} from "react";
import {useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";

import alipayImg from '../res/payment_ic_alipay@2x.png';
import wechatImg  from '../res/payment_btn_wechat@2x.png';

//预付费。
export const PreBuy = ()=>{
    console.log("购买预付费套餐");
    let history = useHistory();

    const [isZhifubao,setIsZhifubao] = useState(true);
    // const verifiedMobile = useSelector(state => state.dataVerifiedMobile);

   

    const handleZhifubao = (e)=>{
        setIsZhifubao(true);
    }
    const handleWechat= (e)=>{
        setIsZhifubao(false);
    }
    const getQueryString = (name, search) => {
        search = search ||  window.location.search.substr(1) || window.location.hash.split("?")[1];
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        if(search===undefined){
            return "";
        }
        let r = search.match(reg);
        if (r != null) return  unescape(r[2]); return null;
    }
    const goback = ()=>{
        if(history.length === 1){
            console.log("跳转过去");
            history.push('/');
        }else{
            console.log("跳转回去");
            history.goBack();
        }
       
    }
    const handlePay = ()=>{
        console.log("请求支付");
        // let orderNo = (new URLSearchParams(window.location.hash)).get("order_no");

        Prebuy(localStorage.getItem('phone'),isZhifubao?3:2,(resp)=>{
            if(isZhifubao){
                console.log("支付宝订单", resp.data);
                if(resp.data.code==200){
                    // 支付宝返回的是一个form表达，js模拟点击跳转到支付界面
                    let div = document.createElement('div');
                    div.id = 'alipay';
                    div.innerHTML = resp.data.data;
                    document.getElementById("mobile-container").appendChild(div);
                    console.log(document.getElementById("alipaysubmit"));
                    document.getElementById("alipaysubmit").submit();
                }else{
                   
                    message.error({
                        content: resp.data.message,
                        style: {
                          marginTop: '40vh',
                        },
                      });
                }
              
            }else{
                if(resp.data.code==200){
                    // window.location.href =  resp.data.data.wx_url;
                    window.location.href = resp.data.data;
                }else{
                    message.error({
                        content: resp.data.message,
                        style: {
                          marginTop: '40vh',
                        },
                      });
                }
                
            }
            console.log(resp);
        })
        console.log("支付函数末尾");
    }
    return(<div style={{textAlign:"center"}} className="mobile-container" id="mobile-container">
        <div className="header" style={{height:"44px",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Button shape="circle" icon={<LeftOutlined />} onClick={goback} style={{position:"absolute",left:"15px",border:"0"}}></Button>
            <div style={{fontWeight:"bold",fontFamily:"PingFang SC",fontSize:"18px",color:"#333333"}}>收银台</div>
         </div>
         <div style={{display:"flex",justifyContent:"space-around",marginTop:"42px",marginBottom:"42px"}}>
            <div>
                <div>套餐时长</div>
                <div style={{color:"#FF8600",fontSize:"18px",fontFamily:"PingFang SC",lineHeight:"25px",marginTop:"10px"}}>60分钟</div>
            </div>
            <Divider type="vertical" style={{height:"4em"}}></Divider>
            <div>
                <div>订单金额</div> 
                <div style={{color:"#FF8600",fontSize:"18px",fontFamily:"PingFang SC",lineHeight:"25px",marginTop:"10px"}}>68元</div>
            </div>
         </div>
         <div style={{backgroundColor:"#F2F2F2",width:"100%",height:"12px"}}></div>
        <div>
            <div style={{color:"#999999", marginLeft:"16px",marginTop:"16px",textAlign:"left"}}>选择支付方式</div>
            <div style={{marginTop:"24px",display:"flex"}}>
                <img src={alipayImg} style={{width:"26px",height:"26px",marginLeft:"26px"}}></img>
                <div style={{fontSize:"16px",marginLeft:"8px"}}>支付宝</div>
                <Checkbox style={{right:"16px",position:"relative",marginTop:"2px",marginLeft:"auto",right:"16px"}} name="payMethod" checked={isZhifubao} onChange={handleZhifubao}>
                </Checkbox>
            </div>
            <Divider  style={{marginLeft:"16px", marginRight:"16px",width:"calc( 100% - 32px )", minWidth:"50%"}}></Divider>
            {/* <Divider  ></Divider> */}
            <div style={{marginTop:"24px",display:"flex"}}>
                <img src={wechatImg} style={{width:"26px",height:"26px",marginLeft:"26px"}}></img>
                <div style={{fontSize:"16px",marginLeft:"8px"}}>微信</div>
                <Checkbox style={{right:"16px",position:"relative",marginTop:"2px",marginLeft:"auto",right:"16px"}} name="payMethod" checked={!isZhifubao} onChange={handleWechat}>
                </Checkbox>
            </div>
            <Divider  style={{marginLeft:"16px", marginRight:"16px",width:"calc( 100% - 32px )", minWidth:"50%"}}></Divider>
            <Button style={{marginLeft:"16px",marginRight:"16px",backgroundColor:"#FF8600",
                width:"calc( 100% - 32px)", border:"0", color:"#FFFFFF",
                height:"45px",borderRadius:"8px",
                marginTop:"16px",
                paddingTop:"12px",paddingBottom:"12px",
                fontSize:"16px", fontFamily:"PingFang SC"}} 
                onClick={handlePay}
                >
                    去支付 ￥68
            </Button>
        </div>
        
    </div>);
}