import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {Api} from './api-define';
import RequestHooksBack from '../utils/http_hooks_back';

export const CreateDialBack =(phone,lawer_id,msg,callback)=>{
    const state = Store.getState();
    console.log("请求回拨号码");
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.AskDialBack,
        method: "POST",
        data: {"phone":phone,"content":msg,"lawer_id":lawer_id}
    },  (Response)=>{
            // Store.dispatch({type:ReducerType.DataUserInfo,data:Response.data});
            if(callback!==undefined){
                callback(Response);
            }
        }
    );
}