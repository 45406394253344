import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {Api} from './api-define';
import RequestHooksBack from '../utils/http_hooks_back';

export const AskHelp =(phone,body,actionType,callback)=>{
    const state = Store.getState();
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.PostSuit,
        method: "POST",
        data: {"phone":phone,"content":body,"help_type":actionType}
    },  (Response)=>{
            Store.dispatch({type:ReducerType.DataUserInfo,data:Response.data});
            if(callback!==undefined){
                callback(Response);
            }
        }
    );
}