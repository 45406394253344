import axios from 'axios';

export default function RequestHooksBack(option,callback,fail=undefined) {
    let { data, url, method = "POST" } = option;
    url = url + "?t=" + Date.parse(new Date());
    const options = {
        url: url,
        method: method,
        data: data,
    };
    axios.defaults.headers.common.Authorization = window.localStorage.accessToken
    if (method === "GET" || method === "DELETE") {
        options.params = data;
        console.log("get请求",data);
      } else {
        options.data = data;
      }
    return axios(options)
        .then(response => {
            if(callback!==undefined){
                callback(response);
            }
        })
        .catch(error => {
            console.log("service error",url,data);
            const { response, message } = error;
            let msg;
            let statusCode;

            if (response && response instanceof Object) {
                const { data, statusText } = response;
                statusCode = response.status;
                msg = data.message || statusText;
            } else {
                statusCode = 600;
                msg = error.message || "Network Error";
            }
            if(fail!==undefined){
                fail(response);
            }
            /* eslint-disable */
            return Promise.reject({
                statusCode,
                message: msg
            });
        });
}