import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {Api} from './api-define';
import RequestHooksBack from '../utils/http_hooks_back';
import {mobileVendor,mobileModel,getUA} from 'react-device-detect';

export const getVerifyCode =(phone,callback)=>{
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    const state = Store.getState();
    RequestHooksBack({
        url: baseUrl + Api.GetVerifyCode,
        method: "POST",
        data: {"phone":phone}
    },  (Response)=>{
            Store.dispatch({type:ReducerType.DataUserInfo,data:Response.data});
            if(callback!==undefined){
                callback(Response);
            }
        }
    );
}


export const verifyMobile=(phone,code,callback)=>{
    // let venderInfo = "mobileModel:"+mobileModel + "; mobileVendor:"+mobileVendor +"; getUA:"+ getUA;
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    let channel = localStorage.getItem("user_channel")===null?"default":localStorage.getItem("user_channel");
    RequestHooksBack({
        url: baseUrl + Api.VerifyPhone,
        method: "POST",
        data: {"phone":phone,'code':code,'mobile_model':mobileModel,'mobile_vendor':mobileVendor,'browser_ua':getUA,'user_channel':channel}
    },  (Response)=>{
            // localStorage.setItem('phone', Response.data.data);
            // Store.dispatch({type:ReducerType.DataVerifiedMobile,data:Response.data});
            if(callback!==undefined){
                callback(Response);
            }
        }
    );
}