
import { Button, Divider ,Input  ,Radio,Cascader,message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import './lawsuit.css';
import provinces from "china-division/dist/provinces.json";
import cities from "china-division/dist/cities.json";
import { useState } from 'react';
import {AskHelp} from '../service/help';
import { useHistory } from "react-router-dom";

const { TextArea } = Input;

const Address = ({onChangeArea}) =>{
    cities.forEach(city => {
        const matchProvince = provinces.filter(
          province => province.code === city.provinceCode
        )[0];
        if (matchProvince) {
          matchProvince.children = matchProvince.children || [];
          matchProvince.children.push({
            label: city.name,
            value: city.code,
            children: city.children
          });
        }
      });
    const onChange = (value)=>{
        if(onChangeArea){
            onChangeArea(value);
        }
        console.log("选择区域",value);
    }  
      const options = provinces.map(province => ({
        label: province.name,
        value: province.code,
        children: province.children
      }));
    return(
        <Cascader
      options={options}
      showSearch
      placeholder="请选择地址"
      onChange={onChange}
    />
    );
}


const SuitType = ({onChangeType})=>{
    const suitOptions = [
        { label: '婚姻家庭', value: 1 },
        { label: '借贷担保', value:2  },
        { label: '劳动用工', value: 3 },
        {label:'刑事犯罪',value:4},
        {label:'交通意外',value:5},
        {label:'房产物业',value:6},
        {label:'公司商事',value:7},
        {label:'合同协议',value:8},
        {label:'侵权赔偿',value:9},
        {label:'消费维权',value:10},
        {label:'医疗纠纷',value:11},
        {label:'名誉诽谤',value:12},
        {label:'律师见面',value:13},
        {label:'知识产权',value:14},
        {label:'其他事项',value:15},
        
      ];
    const getLabel = (v)=>{
        for (let i = 0; i < suitOptions.length; i++) {
            if(suitOptions[i].value == v){
                return suitOptions[i].label;
            }
        }
    }
    return(
        <div>
            <div className="container-margin" style={{ fontSize:"17px",marginTop:"16px",marginBottom:"10px",fontWeight:"600" }}>
            官司类型
            </div>
             <Radio.Group
                className="lawsuit-type-container container-margin"
                onChange={e=>{
                    if(onChangeType!=undefined){
                        onChangeType(e.target.value,getLabel(e.target.value));
                    }
                }}
              style={{ marginTop:"6px"}} defaultValue={1} buttonStyle="solid" optionType="button" options={suitOptions}>
            </Radio.Group>
            
        </div>
    );
}

export const Lawsuit = ()=>{
    let history = useHistory();

    const [desc,setDesc] = useState("");
    const [suitType,setSuitType] = useState(1);
    const [suitTypeStr,setSuitTypeStr] = useState("");

    const [character,setCharacter] = useState(1);
    const handleChangeType =(v,str)=>{
        console.log("切换类型",v,str);
        setSuitType(v);
        setSuitTypeStr(str);

    }
    
    const handSubmit = (e)=>{
      
        let body = "描述："+desc+",类型:"+suitTypeStr;
        AskHelp(localStorage.getItem('phone'),body,1,(resp)=>{
            if(resp.data.code==200){
                message.success({
                    content: "提交成功!法律顾问稍后将与你联系",
                    style: {
                      marginTop: '80vh',
                    },
                    duration:3
                  });
            }else{
                message.error(resp.data.message);
            }
        })
    }
    const goback = ()=>{
        if(history.length === 1){
            console.log("跳转过去");
            history.push('/');
        }else{
            console.log("跳转回去");
            history.goBack();
        }
       
    }
    return (
        <div>
            <div className="header" style={{height:"44px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Button shape="circle"  onClick={ goback } icon={<LeftOutlined />} style={{position:"absolute",left:"15px",border:"0"}}></Button>
                <div style={{fontWeight:"bold",fontFamily:"PingFang SC",fontSize:"18px",color:"#333333"}}>我要打官司</div>
            </div>
            <Divider orientation="left" style={{marginTop:"0px",marginBottom:"0px"}} plain ></Divider>

           <SuitType onChangeType={handleChangeType}></SuitType>
            <div className="block-top" style={{width:"100%",height:"12px",backgroundColor:"#F2F2F2"}}></div>
            
           
            <div className="container-margin block-top " >
                <div style={{marginBottom:"16px",fontSize:"16px", fontWeight:"600"}}>案件详情</div>
                <TextArea style={{width:"100%"}}
                    onChange={(e)=>{setDesc(e.target.value);}}
                    placeholder="请描述您的诉求和案情要点"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    />
            </div>
            <div className="container-margin">
                <Button type="primary"
                    onClick={handSubmit}
                block style={{height:"40px", marginTop:"24px", width:"100%",backgroundColor:"#FF8600",border:"none",fontSize:"16px",paddingTop:"2px"}}>
                    提交</Button>
                
            </div>
           

        </div>
    );
}