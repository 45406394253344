import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {Api} from './api-define';
import RequestHooksBack from '../utils/http_hooks_back';

export const initInfo =(callback)=>{
    const state = Store.getState();
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.InitInfo,
        method: "GET",
    },  (Response)=>{
        console.log("===>",Response,Response.data.data);
        console.log(Response.data.data.lawer_name);
            Store.dispatch({type:ReducerType.DataInitInfo, 
                data:{wechat:Response.data.data.wechat, phone:Response.data.data.phone, dataLawerName:Response.data.data.lawer_name,lawerId:Response.data.data.lawer_id}});
            if(callback!==undefined){
                callback(Response);
            }
        }
    );
}
