import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {Api} from './api-define';
import RequestHooksBack from '../utils/http_hooks_back';

/***********
 * 订单号，
 * 支付渠道，2为微信，3为支付宝。
 *      支付宝获取到的是一个innerHTML （form表单），需要在js里面执行form的submit方法
 **************/
export const queryH5Pay = (order_no,pay_channel,cb)=>{
    const state = Store.getState();
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.QueryPayUrl,
        method: "GET",
        data: {"order_no":order_no,"pay_channel":pay_channel}
    },  (Response)=>{
            if(cb!==undefined){
                cb(Response);
            }
        }
    );
}


export const queryOrder = (order_no,cb)=>{
    const state = Store.getState();
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.QueryOrderInfo,
        method: "GET",
        data: {"order_no":order_no}
    },  (Response)=>{
        //{period:'0分0秒',money:'0元'}
            console.log(Response.data);
            console.log(Response.data.data.period);
            Store.dispatch({type:ReducerType.DataPayInfo,data:{period:Response.data.data.period,money:Response.data.data.money}});
            if(cb!==undefined){
                cb(Response);
            }
        }
    );
}



export const Prebuy = (phone,pay_channel,cb)=>{
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.PrebuyUrl,
        method: "GET",
        data: {"phone":phone,"pay_channel":pay_channel}
    },  (Response)=>{
            if(cb!==undefined){
                cb(Response);
            }
        }
    );
}