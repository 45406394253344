
export const ReducerType = {
    DataUserInfo :'DataUserInfo',
    UIShowDialConfirm:'UIShowDialConfirm',
    UIShowCopyConfirm:'UIShowCopyConfirm',
    DataWechat:'DataWechat',
    DataPaySuccess:'DataPaySuccess',
    DataPhone:'DataPhone',
    DataInitInfo:'DataInitInfo',
    DataPayInfo:'DataPayInfo',
    DataLawerName:'DataLawerName',
    UIShowInputMobile:'UIShowInputMobile',
    // DataVerifiedMobile:'DataVerifiedMobile',
    UIShowChargeInfo:'UIShowChargeInfo',
    DataAxBPhoneA:'DataAxBPhoneA',
}



const defaultState = {
    dataUserInfo:{},
    uIShowDialConfirm:false,
    uIShowCopyConfirm:false,
    dataWechat:'lvshi558',
    dataPaySuccess:false,
    dataPhone:'15067891234',
    dataPayInfo:{period:'0分0秒',money:'0元'},
    dataLawerName:'律师',
    dataLawerId:0,
    uIShowInputMobile:false,
    // dataVerifiedMobile:'',  //客户的经过验证的手机号。
    uIShowChargeInfo:false,
    dataAxBPhoneA:'',
}

const reduer = (state = defaultState,action) => {
    switch(action.type){
        case ReducerType.DataUserInfo:
            console.log("store修改用户信息");
            return {...state,dataUserInfo:action.data}; 
        case ReducerType.UIShowDialConfirm:
            console.log("修改可见性",action.data);
            return {...state,uIShowDialConfirm:action.data};
        case ReducerType.UIShowCopyConfirm:
            console.log("显示或隐藏复制界面",action.data);
            return {...state,uIShowCopyConfirm:action.data};
        case ReducerType.DataWechat:
            return {...state,dataWechat:action.data};
        case ReducerType.DataPaySuccess:
            return {...state,dataPaySuccess:action.data};
        case ReducerType.DataPhone:
            return {...state,dataPhone:action.data};
        case ReducerType.DataInitInfo:
            console.log("重置律师名字",action.data.lawer_name);
            return {...state,dataPhone:action.data.phone, dataWechat:action.data.wechat,dataLawerName:action.data.dataLawerName,dataLawerId:action.data.lawerId};
        case ReducerType.DataPayInfo:
            return {...state,dataPayInfo:{period:action.data.period,money:action.data.money}};
        case ReducerType.UIShowInputMobile:
            console.log("是否显示手机号",action.data);
            return {...state,uIShowInputMobile:action.data};
        // case ReducerType.DataVerifiedMobile:
        //     if(action.data!= undefined && action.data!=null){
        //         localStorage.setItem('phone', action.data);
        //         return {...state,dataVerifiedMobile:action.data};
        //     }else{
        //         localStorage.removeItem('phone');
        //         return {...state,dataVerifiedMobile:''};
        //     }
            
        case ReducerType.UIShowChargeInfo:
            return {...state,uIShowChargeInfo:action.data};
        case ReducerType.DataAxBPhoneA:
            return {...state,dataAxBPhoneA:action.data};
        default:
            return state;
    }
}

export default reduer;